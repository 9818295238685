@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&display=swap");

:root {
  --headerheight: 50px;
  zoom: 100%;
}


html,
body {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}

.ant-table-content {
  overflow: auto hidden;
  font-size: smaller;
}

.login {
  /* Size & position */
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(assets/backgroundlogin.jpg);

  .inner {
    min-width: 400px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cecece;
      margin-bottom: 20px;
    }
  }
}

.layout {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  display: flex;

  .rightitem {
    display: flex;
    align-items: center;
  }

  .header {
    height: var(--headerHeight);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightNav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
    }

    .text {
      color: whitesmoke;
    }
  }
}

.logoutButton {
  padding: 7px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.bodyHolder {
  width: 100%;
  min-height: calc(100vh - var(--headerHeight));
  display: flex;
  background: linear-gradient(rgba(247, 247, 247, 0.5), rgba(247, 247, 247, 1));
  position: relative;
  overflow: hidden;
}
.MysideBar ul.css-ewdv3l li a img {
  width: 18px;
}
.css-1wvake5 {
  width: 270px !important;
  min-width: 270px !important;
}

.css-1wvake5.ps-collapsed {
  width: 65px !important;
  min-width: 65px !important;
}

.css-16jesut > .ps-menu-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.css-1t8x7v1 > .ps-menu-button {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.MysideBar {
  border: none !important;
  box-shadow: none !important;
  padding: 10px !important;
  .css-dip3t8 {
    background-color: white;
    scrollbar-width: thin;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 8px;
    gap: 480px;
    background: #fbfbfb;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }

  .css-dip3t8::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0.5;
  }

  .menu1 {
    color: #3182ce;
  }

  h2 {
    color: white;
  }
}

.mainContent {
  flex: 1;
  max-height: calc(100vh - var(--headerHeight));
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
  padding-right: 1vw;
}

.card {
  background-size: cover;
  background-color: white;
  font-size: 1rem;
  font-weight: 400;
  margin: 20px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.cardHeader {
  justify-content: space-between; /* Aligns content with space between */
  background-color: #0a9; /* AppBar background color */
  color: #fff; /* Text color */
  position: relative; /* Keeps the header relative to the card */
  margin-bottom: 10px;
  align-items: center;
}

.borderline {
  border: 1px solid #0a9;; /* Teal-colored border */
  background-color: #fff; /* Background color for the content */
}
.headContent span {
  /* font-family: Fixel Text; */
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.headContent {
  margin-left: 20px;
  color: #fff; /* Text color */
  // font-family: Fixel Display;
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  text-transform: uppercase; /* Make the text all caps */

}

.divContainer {
  display: flex;
  justify-content: space-between; /* Space between left and right content */
  align-items: center; /* Vertically center the content */
}

.leftContent {
  margin-left: 20px !important;
  display: flex;
  gap: 20px; /* Optional: Adjust space between totalRevenue and totalCost */
}



.rightContent {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
}


.rightContent button:hover {
  background: #fff !important;
  border-color: #d9d9d9 !important;
  color: #000 !important;
}


.bbutton {
  height: 40px;
  padding: 8px 20px 8px 16px;
  font-size: 14px;
  margin-right: 20px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: center; /* Corrected from 'centre' to 'center' */
  background: #fff !important;
  font-family: "DM Sans", sans-serif !important;
  margin: 0 0 0 15px;
  border: 1px solid #0072ce; /* Blue border */
  color: #0072ce; /* Blue text */
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.bbutton:hover {
  background: #0072ce !important; /* Blue background on hover */
  border-color: #0072ce !important; /* Keep the blue border on hover */
  color: #fff !important; /* White text on hover */
}

.customSelect,
.customInput {
  width: 40;
  display: flex;
  align-items: center; /* Vertically align items */
  justify-content: center; /* Optional: horizontally center items */
  height: 40px; /* Same height for consistency */
  outline: none !important; /* Remove the default outline */
  border-color: #0072ce !important; /* Set the border color to blue */
  box-shadow: none; /* Remove additional shadows */
  margin-left: 10px;

}

.customSelect .ant-select-selector,
.customInput input {
  border-color: #0072ce !important; /* Match border color for input and select */
  height: 40px; /* Consistent height */
  line-height: 40px; /* Align text vertically */
  padding: 0 12px; /* Adjust padding for proper spacing */
}

.customSelect .ant-select-focused .ant-select-selector {
  border-color: #0072ce !important; /* Maintain blue border when focused */
  box-shadow: 0 0 0 2px rgba(0, 114, 206, 0.2) !important; /* Add a subtle shadow for focus */
}

.css-cqaibf-MuiPaper-root-MuiAppBar-root {
  background-color: white !important;
  box-shadow: none !important;
}

.custom-table .ant-table-thead > tr > th {
  text-transform: capitalize;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-family: "Inter", sans-serif;
  color: #00000080;
}

.custom-table .ant-table-tbody > tr > td {
  
  color: #2d3748;
  border-bottom: 1px solid #e2e8f0;
  /* font-size: 1rem; */
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.custom-table .ant-table-cell[data-title="Vendor Name"] {
  text-transform: none; /* Or any other transformation you want to apply */
}

.custom-table .ant-table-tbody > tr:nth-child(odd) > td {
  background-color: #ffffff;
}

.custom-table .ant-table-row {
  height: 2vh !important;
  /* Set the height of the rows */
}

.custom-table {
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 10px;
  margin-top: 20px !important;

}

/* Center-align text in table cells */
.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td {
  text-align: center;
}
h4 {
  margin-left: -1.5vw;
  margin-top: 0.8vw;
}

h3 {
  margin-top: 0.8vw;
}


.ordercreation {
  background-size: cover;
  display: flex;
  
  justify-content: space-between;

  .cardcart1 {
    margin: 20px;
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-color: white;
    padding: 12px;
    width: 50%;

    .cardHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;


    }
  }

  .cardcart2 {
    margin: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-color: white;
    padding: 12px;
    width: 50%;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__portal {
  position: fixed;
  z-index: 9999;
}

.MysideBar ul.css-ewdv3l li a {
  /* font-family: Fixel Text; */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #00000080;
  border-radius: 8px;
}
.MysideBar ul.css-ewdv3l li a:hover,
.activeMenuItem a {
  -webkit-box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 39%);
  -moz-box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 39%);
  box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 39%);
}
.MysideBar .ps-submenu-content.ps-open.css-z5rm24 {
  background: transparent;
}


.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.login-content {
  display: flex;
  flex-direction: column; /* Changed to column layout */
  align-items: center; /* Center align vertically */
  width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-container {
  padding: 40px;
  width: 100%; /* Ensure form container takes full width */
  max-width: 400px; /* Limit maximum width */
}

.logo-container {
  margin-bottom: 20px; /* Add some space between logo and form */
}

.logo {
  width: 300px;
  height: auto;
  padding: 40px 30px; 
}

.form-item {
  margin-bottom: 10px;
}

.login-button {
  width: 100%;
  border-radius: 4px;
  background-color: #1890ff;
  color: #fff;
}

.login-button:hover {
  background-color: #40a9ff;
}

.link {
  display: block;
  text-align: center;
  color: #1890ff;
}

.link:hover {
  color: #40a9ff;
}


.logo-containersb {
  flex: 1;
  border-radius: 8px 0 0 8px;
  padding: 20px;
}

.logosb {
  width: 100%; /* Adjust the width of the logo to cover the container */
  height: auto;

}
.totalRevenue {
  font-size: 12px;
  font-weight: bold;
  color: #3366cc; /* Example color */
}

.section {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #008080;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.sectionHeader h3 {
  margin: 0;
  color: #008080;
}

.sectionBody {
  padding-top: 10px;
}
/* Recipe View Drawer */
.recipeviewer-header {
  margin-bottom: 20px;
}

.recipeviewer-header .ant-typography {
  color: #008080;
}

.recipeviewer-bottom-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.recipeviewer-section {
  flex: 1;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.recipeviewer-section .ant-typography {
  color: #008080;
}

.section-header {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid #008080;
}

.recipeviewer-cooking-details {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.recipeviewer-close-button {
  background-color: #008080;
  border-color: #008080;
  font-weight: bold;
  font-size: 16px;
}

.recipeviewer-close-button:hover {
  background-color: #006f6f;
  border-color: #006f6f;
}


.imageview{
  width:100px;
  height:100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  margin-left: 40%;
  box-shadow: 3px 3px 2px 0px rgba(227,218,218,1);
}



.filterGroup {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #555;
}

.selectBox {
  width: 180px;
}


.chartContainer {
 
  width: 100%;
  height: 400px;
}

.piechartContainer {
  width: 100%;
  height: 300px;
}
.modern-upload-btn {
  display: inline-block;
  background-color: white;
  color: teal;
  padding: 6px 12px; /* Smaller padding */
  font-size: 10px; /* Smaller font */
  border: 1px solid teal; /* Blue border */
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modern-upload-btn:hover {
  background-color: #1677ff;
  color: white;
}

.modern-upload-btn input[type="file"] {
  display: none;
}